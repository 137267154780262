
(function () {
    "use strict";

    document.getElementById('jBtnOptionaleZusatzservices').addEventListener('click', function () {

        var $btn = this;
        var $formWrapper = document.getElementById('jOptionaleZusatzservices');

        if ($formWrapper.classList.contains('Display--none')) {

            $formWrapper.classList.remove('Display--none');
            $btn.classList.add('offen');

        } else {

            $formWrapper.classList.add('Display--none');
            $btn.classList.remove('offen');
        }


    }, false);

})();