(function () {

    try {
        var $videoSection = document.getElementById('jSectionVideo');
        var $videoTrigger = document.getElementById('jVideoTrigger');
        var $video = document.getElementById('jVideo');

        $videoTrigger.addEventListener('click', function () {

            $videoSection.classList.add('play');

            // Video abspielen
            $video.play();

        }, false);

        $video.addEventListener('ended', function () {

            $videoSection.classList.remove('play');

            // nach dem Ende das Video wieder auf Start setzen.
            $video.currentTime = 0;

        }, false);

    } catch (e) {

    }

})();