(function (w) {
    "use strict";

    // wenn bereits im Cache raus hier
    if (w.document.documentElement.classList.contains("fonts-loaded")) {
        return;
    }

    var typefaces = {

        Lato: [
            { weight: 300, style: 'normal' },
            { weight: 400, style: 'normal' },
            { weight: 600, style: 'normal' }
        ]

    };

    var variants;

    Object.keys(typefaces).forEach(function (name) {

        variants = typefaces[name].map(function (variant) {

            var loader = new w.FontFaceObserver(name, variant);

            return loader.load();

        });

    });

    w.Promise.all(variants)
        .then(function () {

            // Schriften sind da, html Element Klasse geben
            document.documentElement.classList.add('fonts-loaded');

            // für diese Session speichern
            sessionStorage.fontsLoaded = true;

        })
        .catch(function () {

            document.documentElement.classList.remove('fonts-loaded');
            sessionStorage.fontsLoaded = false;

        });

}(this));