
(function () {
    "use strict";

    var $formWrapper = document.getElementById('jAnfrage');
    var $form = document.getElementById('jForm');
    var $paketText = document.getElementById('jPaketName');
    var $anfrageButtons = document.querySelectorAll('.jAnfrageButton');

    for (var i = 0; i < $anfrageButtons.length; i++) {

        $anfrageButtons[i].addEventListener('click', function () {

            var $paketNameInput = document.querySelector('.jPaketName');
            var paketName = this.getAttribute('data-paket-name');

            $paketText.textContent = paketName;
            $paketNameInput.value = paketName;

            $formWrapper.classList.remove('Display--none');

        }, false);

    }

    document.getElementById('jCloseForm').addEventListener('click', function () {
        $formWrapper.classList.add('Display--none');
    }, false);



    $form.addEventListener('submit', submitAnfrage, false);

    function submitAnfrage(e) {

        // disable form POST
        e.preventDefault();

        var xhr = new XMLHttpRequest();
        var inputs = {};


        // nur ein Formualr & html5 validierung
        if ($form.checkValidity() === true) {

            xhr.open("POST", $form.action, true);
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.onload = function (e) {

                // https://restfulapi.net/http-status-codes/
                if (xhr.readyState ===4 && xhr.status === 204) {
                    $formWrapper.textContent = "Vielen Dank für Ihre Anfrage, wir werden uns umgehend bei Ihnen melden.";
                }
            }

            for (var i = 0, j = $form.length; i < j; i++) {

                var that = $form[i];

                // anstatt name werden hier is id's benutzt um das Objekt zu erstellen.
                inputs[that.id] = that.value;
            }

            xhr.send(JSON.stringify(inputs));
        }
    }

})();