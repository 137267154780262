
(function () {
    "use strict";

    var $navigationPanel = document.getElementById('jNavigation');
    var $navigationOverlay = document.getElementById('jNavigationOverlay');
    var naviOffenClassName = 'offen';
    var overlayAktivClassName = 'aktiv';

    document.getElementById('jNavigationTrigger').addEventListener('click', function () {
        $navigationPanel.classList.add(naviOffenClassName);
        $navigationOverlay.classList.add(overlayAktivClassName);
    }, false);

    $navigationOverlay.addEventListener('click', function () {
        $navigationPanel.classList.remove(naviOffenClassName);
        $navigationOverlay.classList.remove(overlayAktivClassName);
    }, false);

    document.getElementById('jCloseMenu').addEventListener('click', function () {
        $navigationPanel.classList.remove(naviOffenClassName);
        $navigationOverlay.classList.remove(overlayAktivClassName);
    }, false);

})();